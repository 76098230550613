import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

const HASH_TAG = "#";
const LINKED_IN_LINK = "https://www.linkedin.com/company/djamma-dev";
const FACEBOOK_LINK = "https://www.facebook.com/djammadev";
const TWITTER_LINK = "https://twitter.com/djammadev";
const APP_STORE_LINK = "https://apps.apple.com/fr/app/chiesulu/1660809985?l=fr%3Fplatform#?platform=iphone";
const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.djammadev.chiesulu.volaille";
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;


const {$, grecaptcha} = window;

function Header(props) {
    const [isScrollLoaded, setIsScrollLoaded] = useState(false);
    useEffect(() => {
        // initialization of header
        $.HSCore.components.HSHeader.init($('#js-header'));
        $.HSCore.helpers.HSHamburgers.init('.hamburger');
    });

    useEffect(() => {
        if (!isScrollLoaded) {
            $.HSCore.components.HSScrollNav.init($('#js-scroll-nav'), {
                duration: 700
            });
            setIsScrollLoaded(true);
        }
    }, [isScrollLoaded]);

    return (
        <header id="js-header" className="u-header u-header--sticky-top u-header--show-hide u-header--toggle-section"
                data-header-fix-moment="100"
                data-header-fix-effect="slide">
            <div className="u-header__section u-shadow-v27 g-bg-white g-transition-0_3 g-py-12 g-py-20--md">
                <nav className="navbar navbar-expand-lg py-0 g-px-15">
                    <div className="container g-pos-rel">
                        {/* Logo */}
                        <a href={HASH_TAG} className="js-go-to navbar-brand u-header__logo">
                            <img className="u-header__logo-img u-header__logo-img--main g-width-40"
                                 src="/assets/img/appstore.png" alt="Chiesulu"/>
                        </a>
                        {/* End Logo */}

                        <div id="navBar" className="collapse navbar-collapse" data-mobile-scroll-hide="true">
                            {/* Navigation */}
                            <div className="navbar-collapse align-items-center flex-sm-row">
                                <ul id="js-scroll-nav"
                                    className="navbar-nav g-flex-right--xs text-uppercase w-100 g-font-weight-700 g-font-size-11 g-pt-20 g-pt-0--lg">
                                    <li className="nav-item g-mr-15--lg g-mb-12 g-mb-0--lg active">
                                        <a href={"#home"} className="nav-link p-0">Accueil <span
                                            className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item g-mx-15--lg g-mb-12 g-mb-0--lg">
                                        <a href={"#about"} className="nav-link p-0">A Propos</a>
                                    </li>
                                    <li className="nav-item g-mx-15--lg g-mb-12 g-mb-0--lg">
                                        <a href={"#screens"} className="nav-link p-0">Ecrans</a>
                                    </li>
                                    <li className="nav-item g-ml-15--lg g-mb-12 g-mb-0--lg">
                                        <a href={"#contact"} className="nav-link p-0">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            {/* End Navigation */}
                            <a className="btn btn-lg text-uppercase u-btn-primary g-font-weight-700 g-font-size-11 g-rounded-10 g-px-13 g-py-10 mb-0 g-ml-25--lg"
                               target={"_blank"} rel={"noreferrer"}
                               href="https://chiesulu.web.app">Essayer</a>
                        </div>

                        {/* Responsive Toggle Button */}
                        <button
                            className="navbar-toggler btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-15 g-right-0"
                            type="button"
                            aria-label="Toggle navigation"
                            aria-expanded="false"
                            aria-controls="navBar"
                            data-toggle="collapse"
                            data-target="#navBar">
                            <span className="hamburger hamburger--slider">
                              <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                              </span>
                            </span>
                        </button>
                        {/* End Responsive Toggle Button */}
                    </div>
                </nav>
            </div>
        </header>
    );
}

function Home(props) {
    useEffect(() => {
        // initialization of scroll animation
        $.HSCore.components.HSOnScrollAnimation.init('[data-animation]');
    });

    return (
        <section id="home" className="g-theme-bg-gray-light-v1 g-pt-90">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-7 ml-lg-auto d-flex text-center text-md-left g-font-size-16">
                        <div className="align-self-center">
                            <h2 className="text-uppercase g-line-height-1_3 g-font-size-36 g-mb-20 g-mb-30--lg"> Chiesulu
                                <br/><strong>Simplifie l'élévage</strong></h2>
                            <p className="g-mb-20 g-mb-35--lg">Votre activité c’est de prendre des poussins,
                                les faire grandir et ensuite vendre les poulets ?
                                <br/>
                                Alors, nous avons mis en place une application intuitive qui permet de gérer votre
                                activité
                                d’aviculteurs.
                                <br/>
                                L’application <strong>Chiesulu</strong> est ergonomique, elle est pensée et conçue dans
                                l'optique de rendre le suivi très facile et de vous faire gagner du temps.
                                <br/>
                                <strong style={{color: 'blue', fontWeight: 'bold'}}>Nouveauté !</strong>
                                <br/>
                                Vous pouvez gérer votre activité sans connexion internet. Les données seront synchronisées dès que vous serez connecté.
                            </p>
                            <a target={"_blank"} href={APP_STORE_LINK} rel="noreferrer"
                               className="btn btn-md u-btn-primary g-font-weight-700 text-uppercase text-left g-rounded-10 g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md">
                              <span className="float-right g-font-size-13">
                                Télécharger
                                <span
                                    className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">App Store</span>
                              </span>
                                <i className="fa fa-apple float-left g-font-size-26 g-mr-15"/>
                            </a>
                            <a target={"_blank"} href={PLAY_STORE_LINK} rel="noreferrer"
                               className="btn btn-md u-btn-darkpurple g-font-weight-700 text-uppercase text-left g-rounded-10 g-py-10 g-mb-15 g-mx-10 g-mx-0--md">
                                <span className="float-right g-font-size-13">
                                    Télécharger
                                    <span
                                        className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">Play Store</span>
                                </span>
                                <i className="fab fa-google-play float-left g-font-size-26 g-mr-15"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-5 g-overflow-hidden">
                        <img className="img-fluid"
                             src="/assets/img/chicks.png"
                             alt="Chiesulu"
                             data-animation="slideInUp"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

function About(props) {
    return (
        <section id="about" className="g-pt-90">
            <div className="container text-center g-max-width-750 g-font-size-16 g-mb-30">
                <div className="text-uppercase g-line-height-1_3 g-mb-20">
                    <h4 className="g-font-weight-700 g-font-size-11 g-mb-15"><span
                        className="g-color-primary">{props.index}.</span> {props.title}</h4>
                    <h2 className="g-line-height-1_3 g-font-size-36 mb-0">Chiesulu : <strong>gestion de
                        poulaillers</strong></h2>
                </div>
                <p>
                    L’aviculture est un secteur qui se démocratise au Mali en ce moment.
                    Beaucoup de jeunes se lancent dans cette activité. Vous connaissez surement un proche qui fait cette
                    activité.
                </p>
                <p>
                    Il y a différente forme d’aviculture
                </p>
                <ul className={"mb-0 text-justify"}>
                    <li>
                        La production des oeufs, avec des poules pondeuses
                    </li>
                    <li>
                        La production des poussins d’1 jour
                    </li>
                    <li>
                        Nourrissage des poussins d’1 jour pour ensuite vendre la chaire à terme (45 jours en
                        moyenne)
                    </li>
                </ul>
            </div>

            <div className="container px-0">
                <div className="row no-gutters">
                    <div className="col-sm-12 col-lg-4 g-bg-darkpurple">
                        {/* Article */}
                        <article className="u-block-hover">
                            <figure className="g-overflow-hidden">
                                <img className="img-fluid u-block-hover__main--zoom-v1"
                                     src="/assets/img/poulailler.jpeg"
                                     alt="Chiesulu"/>
                            </figure>

                            <div className="g-color-white g-py-40 g-px-30">
                                <h3 className="text-uppercase g-font-weight-700 g-font-size-22 g-color-white g-mb-25">Espace</h3>
                                <p className="g-color-white-opacity-0_8 mb-0">Un endroit est nécessaire pour entretenir
                                    les poussins, et les protéger des prédateurs.
                                    Cet endroit c’est le Poulailler. Le poulailler doit être équipé afin de faciliter
                                    l’entretien des poussins.
                                    Point d’eau, l’électricité, un gardien (peut-être soi-même)..</p>
                            </div>
                        </article>
                        {/* End Article */}
                    </div>

                    <div className="col-sm-12 col-lg-4 g-bg-pink">
                        {/* Article */}
                        <article className="u-block-hover">
                            <figure className="g-overflow-hidden">
                                <img className="img-fluid u-block-hover__main--zoom-v1"
                                     src="/assets/img/bands.jpeg"
                                     alt="Chiesulu"/>
                            </figure>

                            <div className="g-color-white g-py-40 g-px-30">
                                <h3 className="text-uppercase g-font-weight-700 g-font-size-22 g-color-white g-mb-25">Acquisition
                                    des poussins</h3>
                                <p className="g-color-white-opacity-0_8 mb-0">
                                    Une fois le poulailler aménagé, on peut acheter nos poussins d’1 jour pour les
                                    mettre dans le poulailler et commencer l’élevage proprement dit.
                                    De l’achat, des poussins a leur écoulement est une période, l’ensemble des poussins
                                    entretenus est appelé une Bande.
                                    Donc une période correspond à l’achat des poussins jusqu’à leur écoulement (vente).
                                </p>
                                <ul>
                                    <li>
                                        Entretien consiste à effectuer des travaux, des achats, plus généralement
                                        des dépenses pour la bonne croissance des poussins.
                                    </li>
                                    <li>
                                        La gestion des mortalités, malheureusement il est très difficile d’élever
                                        des poussins sans qu’il en a qui meurent. Mais il est interessant de faire
                                        un suivi de ces pertes
                                    </li>
                                    <li>
                                        La vente des poulets lors qu’ils arrivent à maturité.
                                    </li>
                                </ul>
                            </div>
                        </article>
                        {/* End Article */}
                    </div>

                    <div className="col-sm-12 col-lg-4 g-bg-purple">
                        {/* Article */}
                        <article className="u-block-hover">
                            <figure className="g-overflow-hidden">
                                <img className="img-fluid u-block-hover__main--zoom-v1"
                                     src="/assets/img/client.jpeg"
                                     alt="Chiesulu"/>
                            </figure>

                            <div className="g-color-white g-py-40 g-px-30">
                                <h3 className="text-uppercase g-font-weight-700 g-font-size-22 g-color-white g-mb-25">Recherche
                                    de clients</h3>
                                <p className="g-color-white-opacity-0_8 mb-0">
                                    Cette partie est très importante, il faut trouver des clients pour écouler
                                    rapidement des poulets lorsqu’ils arrivent à 45 jours.
                                    L’écoulement rapide des poulets permet d’engager la bande suivante.</p>
                            </div>
                        </article>
                        {/* End Article */}
                    </div>
                </div>
            </div>
        </section>
    );
}

function AppScreens(props) {
    const Screen = (props) => {
        return <div className={`cbp-item ${props.type}`}>
            <a className="cbp-caption cbp-lightbox d-block"
               href={props.link}
               data-title="Custom Title 1">
                <div className="cbp-caption-defaultWrap">
                    <img
                        src={props.link}
                        alt="Chiesulu"/>
                </div>

                <div className="cbp-caption-activeWrap g-bg-primary-opacity-0_6">
                    <div className="cbp-l-caption-alignCenter">
                        <div className="cbp-l-caption-body">
                            <div className="cbp-l-caption-title g-font-size-50">+</div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    }

    useEffect(() => {
        $.HSCore.components.HSCubeportfolio.init('.cbp');
    });
    return (
        <section id="screens" className="g-py-90">
            <div className="container text-center g-max-width-750 g-mb-65">
                <div className="text-uppercase g-line-height-1_3 g-mb-20">
                    <h4 className="g-font-weight-700 g-font-size-11 g-mb-15"><span
                        className="g-color-primary">{props.index}.</span> {props.title}
                    </h4>
                    <h2 className="g-font-size-36 mb-0">Regarger <strong>comment ça marche</strong></h2>
                </div>
            </div>

            <div className="container">
                {/* Filter */}
                <ul id="appScreensCubePortfolioFilter"
                    className="nav justify-content-center d-block d-md-flex u-nav-v5-1 text-uppercase g-line-height-1_4 g-font-weight-700 g-font-size-11 g-nowrap g-brd-bottom--md g-brd-gray-light-v4 g-mb-20 g-mb-70--md">
                    <li className="cbp-filter-item nav-item cbp-filter-item-active"
                        data-filter="*">
                        <span className="nav-link g-px-0--md g-pb-15--md g-mr-30--md">Tout</span>
                    </li>
                    <li className="cbp-filter-item nav-item"
                        data-filter=".login">
                        <span className="nav-link g-px-0--md g-pb-15--md g-mr-30--md">Connexion</span>
                    </li>
                    <li className="cbp-filter-item nav-item"
                        data-filter=".sign-up">
                        <span className="nav-link g-px-0--md g-pb-15--md g-mr-30--md">Profil</span>
                    </li>
                    <li className="cbp-filter-item nav-item"
                        data-filter=".info">
                        <span className="nav-link g-px-0--md g-pb-15--md g-mr-30--md">Communauté</span>
                    </li>
                    <li className="cbp-filter-item nav-item"
                        data-filter=".home">
                        <span className="nav-link g-px-0--md g-pb-15--md g-mr-30--md">Accueil</span>
                    </li>
                    <li className="cbp-filter-item nav-item"
                        data-filter=".client">
                        <span className="nav-link g-px-0--md g-pb-15--md g-mr-30--md">Clients</span>
                    </li>
                </ul>

                <div id="appScreensCubePortfolio" className="cbp"
                     data-controls="#appScreensCubePortfolioFilter"
                     data-layout="grid"
                     data-animation="slideLeft"
                     data-caption-animation="fadeIn"
                     data-x-gap="30"
                     data-y-gap="30"
                     data-media-queries='[
                 {"width": 800, "cols": 4},
                 {"width": 500, "cols": 2},
                 {"width": 320, "cols": 1}
               ]'>

                    {/*Login*/}
                    <Screen type={"login"} link={"/assets/img/screens/login-1.png"}/>
                    <Screen type={"login"} link={"/assets/img/screens/login-2.png"}/>

                    {/*Sign Up*/}
                    <Screen type={"sign-up"} link={"/assets/img/screens/settings.png"}/>

                    {/*Feeds*/}
                    <Screen type={"info"} link={"/assets/img/screens/project-0.png"}/>
                    <Screen type={"info"} link={"/assets/img/screens/project-1.png"}/>
                    <Screen type={"info"} link={"/assets/img/screens/post-0.png"}/>
                    <Screen type={"info"} link={"/assets/img/screens/post-1.png"}/>
                    <Screen type={"info"} link={"/assets/img/screens/post-2.png"}/>

                    {/*Home*/}
                    <Screen type={"home"} link={"/assets/img/screens/account-1.png"}/>
                    <Screen type={"home"} link={"/assets/img/screens/account-2.png"}/>
                    <Screen type={"home"} link={"/assets/img/screens/account-3.png"}/>
                    <Screen type={"home"} link={"/assets/img/screens/account-4.png"}/>
                    <Screen type={"home"} link={"/assets/img/screens/account-5.png"}/>
                    <Screen type={"home"} link={"/assets/img/screens/account-8.png"}/>

                    {/*Client*/}
                    <Screen type={"client"} link={"/assets/img/screens/client-1.png"}/>
                    <Screen type={"client"} link={"/assets/img/screens/client-2.png"}/>
                    <Screen type={"client"} link={"/assets/img/screens/client-3.png"}/>
                    <Screen type={"client"} link={"/assets/img/screens/client-4.png"}/>
                </div>
            </div>
        </section>
    );
}

function Contact(props) {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState('');
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        grecaptcha.ready(function () {
            grecaptcha.execute(RECAPTCHA_KEY, {action: 'submit'}).then(async function (token) {
                console.log(token);
                let data = {contact: {fullName, email, message}, token};
                setNotification('');
                try {
                    let res = await fetch('/api/auth/contact', {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify(data)
                    });
                    let response = await res.json();
                    console.log('response', response);
                    if (res.status !== 200) {
                        throw new Error(JSON.stringify(response));
                    } else {
                        setHasError(false);
                        setNotification('Merci de votre intérêt !');
                        setFullName('');
                        setEmail('');
                        setMessage('');
                    }
                } catch (error) {
                    console.log('error', error);
                    setHasError(true);
                    setNotification('Une erreur est survenue lors de la communication avec le serveur.');
                } finally {
                    setLoading(false);
                }
                console.log(data);
            });
        });
    }
    return (
        <section id="contact" className="g-bg-gray-light-v1 g-pt-90 g-pb-30 g-pb-90--lg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 g-mb-20 g-mb-0--lg">
                        <div className="text-uppercase g-mb-20">
                            <h4 className="g-font-weight-700 g-font-size-11 g-color-white g-mb-15">{props.index}.
                                {props.title}</h4>
                            <h2 className="g-font-size-36 g-color-white mb-0">Restons en <strong>contact</strong>
                            </h2>
                        </div>

                        <p className="g-font-size-16 g-color-white mb-0">
                            Laissez nous votre contact nous vous tiendrons informer dès qu'il y aura du nouveau
                            dans <strong>Chiesulu</strong>
                        </p>
                    </div>

                    <div className="col-lg-7 g-pt-30--lg">
                        <form id="contact-form" onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group g-mb-30">
                                        <input id="inputGroup1_1"
                                               className="form-control h-100 g-color-white g-placeholder-inherit g-bg-transparent g-bg-transparent--focus g-brd-white g-rounded-10 g-px-20 g-py-10"
                                               type="text"
                                               placeholder="Nom complet"
                                               value={fullName}
                                               onChange={e => setFullName(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group g-mb-30">
                                        <input id="inputGroup1_2"
                                               className="form-control h-100 g-color-white g-placeholder-inherit g-bg-transparent g-bg-transparent--focus g-brd-white g-rounded-10 g-px-20 g-py-10"
                                               type="email"
                                               required={true}
                                               placeholder="Email *"
                                               value={email}
                                               onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group g-mb-30">
                                        <textarea id="inputGroup1_3"
                                                  className="form-control g-resize-none g-color-white g-placeholder-inherit g-bg-transparent g-bg-transparent--focus g-brd-white g-rounded-10 g-px-20 g-py-10"
                                                  rows="5"
                                                  placeholder="Message"
                                                  value={message}
                                                  onChange={e => setMessage(e.target.value)}/>
                                    </div>

                                    <div className="text-center text-md-right">
                                        <button
                                            className="btn u-btn-white btn-md text-uppercase g-font-weight-700 g-font-size-12 g-color-black g-rounded-10 g-py-10 g-px-25 mb-0"
                                            disabled={loading}
                                            type='submit'>Envoyer
                                        </button>
                                        {loading && <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />}
                                    </div>
                                </div>
                                {Boolean(notification) &&
                                <div className={`g-font-size-16 g-font-weight-800 g-color-white`}>
                                    <Alert severity={`${hasError ? "error" : "success"}`}>{notification}</Alert>
                                </div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Footer(props) {
    return (
        <footer>
            <ul className="list-inline d-table w-100 mb-0">
                <li className="list-inline-item d-table-cell g-width-1x">
                    <a className="btn btn-lg u-btn-indigo btn-block rounded-0 g-py-20" href={FACEBOOK_LINK}
                       target={"_blank"} rel="noreferrer"><i
                        className="fa fa-facebook"/></a>
                </li>
                <li className="list-inline-item d-table-cell g-width-1x">
                    <a className="btn btn-lg u-btn-pink btn-block rounded-0 g-py-20" href={LINKED_IN_LINK}
                       target={"_blank"} rel="noreferrer"><i
                        className="fa fa-linkedin"/></a>
                </li>
                <li className="list-inline-item d-table-cell g-width-1x">
                    <a className="btn btn-lg u-btn-blue btn-block rounded-0 g-py-20" href={TWITTER_LINK}
                       target={"_blank"} rel="noreferrer"><i
                        className="fa fa-twitter"/></a>
                </li>
            </ul>
        </footer>
    );
}

function GoTop(props) {
    useEffect(() => {
        $.HSCore.components.HSGoTo.init('.js-go-to');
    });
    return (
        <a className="js-go-to u-go-to-v1" href={HASH_TAG}
           data-type="fixed"
           data-position='{
           "bottom": 15,
           "right": 15
         }'
           data-offset-top="400"
           data-compensation="#js-header"
           data-show-effect="zoomIn">
            <i className="hs-icon hs-icon-arrow-top"/>
        </a>
    );
}

function App() {
    return (
        <>
            <Header/>
            <Home/>
            <About index={"01"} title={"A propos de l'app"}/>
            <AppScreens index={"02"} title={"Les écrans de l'app"}/>
            <Contact index={"03"} title={"Nous contacter"}/>
            <Footer/>
            <GoTop/>
        </>
    );
}

export default App;
